<template>
  <div class="min-w-screen min-h-screen flex items-center justify-center">
    <div class="max-w-lg rounded border-1 border-gray-400 text-center">
      <span
        class="
          inline-block
          mb-1
          py-1
          px-3
          bg-gray-200
          text-gray-700 text-xs
          rounded-lg
        "
        >{{ $t("404-error") }}</span
      >
      <h1 class="text-3xl font-semibold mt-5">{{ $t("404-title") }}</h1>
      <p class="my-2">
        {{ $t("404-text") }}
      </p>
      <router-link
        to="/"
        class="
          inline-block
          my-4
          py-2
          px-4
          rounded-lg
          bg-WADARKBLUE-500
          text-white text-sm
          uppercase
        "
        >{{ $t("404-link") }}</router-link
      >
    </div>
  </div>
</template>
